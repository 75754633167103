<template>
  <div class="home-page" v-loading="loading">
    <el-card style="margin-bottom: 20px">
      <div style="margin: -24px">
        <img
          src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021091715173228014407.png"
          alt=""
          style="width: 100%"
        />
      </div>
    </el-card>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card shadow="always">
          <div class="card-item" @click="customerClick">
            <div class="card-item-icon">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021091715153447450838.png" alt="" />
            </div>
            <div class="card-item-desc">
              <span class="desc-count">{{ datalist.customerNum }}</span>
              <span class="desc-title">客户数</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always">
          <div class="card-item" @click="appUserClick">
            <div class="card-item-icon">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021091816155693833740.png" alt="" />
            </div>
            <div class="card-item-desc">
              <span class="desc-count">{{ datalist.appUserNum }}</span>
              <span class="desc-title">APP用户数</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always">
          <div class="card-item" @click="orderNumClick">
            <div class="card-item-icon">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021091816111556243070.png" alt="" />
            </div>
            <div class="card-item-desc">
              <span class="desc-count">{{ datalist.orderNum }}</span>
              <span class="desc-title">订单数</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always">
          <div class="card-item" @click="taskNumClick">
            <div class="card-item-icon">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/20210917151711042358.png" alt="" />
            </div>
            <div class="card-item-desc">
              <span class="desc-count">{{ datalist.taskNum }}</span>
              <span class="desc-title">预约数</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card style="margin-top: 20px">
      <div id="myCharts"></div>
    </el-card>
    <!-- <div class="otherCharts">
      <div id="bread"></div>
      <div id="line"></div>
    </div> -->
  </div>
</template>

<script>
import { postRequestAnthor } from '@/api/index'
export default {
  name: 'dashboard',
  data() {
    return {
      datalist: '',
      loading: true,
      userpower:""
    }
  },
  created(){
    this.userpower = JSON.parse(localStorage.getItem('newToken'))
  },
  mounted() {
    this.lodadata()
  },
  methods: {
    // 获取页面数据
    lodadata() {
      postRequestAnthor('/homepage/homedata').then(res => {
        // console.log(res);
        this.datalist = res
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById('myCharts'))
        // 绘制图表
        myChart.setOption({
          title: {
            text: '月度预约统计',
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['会员预约数', '单品预约数'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {},
          //   },
          // },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.datalist.month.reverse(),
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '单品预约数',
              type: 'line',
              data: this.datalist.dpData.reverse(),
              smooth: true,
            },
            {
              name: '会员预约数',
              type: 'line',
              data: this.datalist.hjData.reverse(),
              smooth: true,
            },
          ],
        })
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },
    // 客户数
    customerClick(){
      if (
        this.userpower.resources.some(item => {
          return item.webId == 2
        })
      ) {
        this.$router.push('/customersManagement')
      } else {
        this.$message({
          message: '暂无客户管理权限',
          type: 'warning',
        })
      }
      
    },
    // App用户数
    appUserClick(){
       if (
        this.userpower.resources.some(item => {
          return item.webId == 9
        })
      ) {
        this.$router.push('/usersManagement')
      } else {
        this.$message({
          message: '暂无用户管理权限',
          type: 'warning',
        })
      }
      
    },
    // 订单数
    orderNumClick(){
       if (
        this.userpower.resources.some(item => {
          return item.webId == 29
        })
      ) {
        this.$router.push('/orderManagement')
      } else {
        this.$message({
          message: '暂无订单管理权限',
          type: 'warning',
        })
      }
      
    },
    // 预约数
    taskNumClick(){
       if (
        this.userpower.resources.some(item => {
          return item.webId == 23
        })
      ) {
        this.$router.push('/appointmentRegister')
      } else {
        this.$message({
          message: '暂无预约任务权限',
          type: 'warning',
        })
      }
      
    }
  },
}
</script>

<style lang="scss" scoped>
.home-page {
  background: transparent !important;
}
.el-card {
  cursor: pointer;
  div{
    color: #FDFDFF ;
  }
}
.card-item {
  display: flex;
  height: 60px;
  justify-content: space-between;
  .card-item-desc {
    // height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .desc-title {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #a9acb5;
    }
    .desc-count {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  .card-item-icon {
    border-right: 2px solid #eee;
    flex: 1;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .item-icon-default {
      color: #fff;
      font-size: 80px;
    }
  }
}
#myCharts {
  width: 100%;
  height: 390px;
  background-color: #fff;
}
.otherCharts {
  height: 250px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  #bread {
    width: 50%;
    height: 250px;
  }
  #line {
    width: 50%;
    height: 250px;
  }
}
</style>
